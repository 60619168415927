<template>

	<div class="user">
		<h4>我的点评</h4>

		<div class="coulist">
			<div class="couitem" v-for="(item,i) in list" :key="i" >
			
				<div class="c2">
                    <div>
                        <p><strong>{{item.goods_name}}</strong></p>
                        <p>
                            <span style="color:#ff9900;margin-right:10px">{{item.status | status}}</span>
                             {{item.create_time}}</p>
                    </div>
					<p>{{item.question}}</p>
				</div>
                <div class="c3" >
                    <p> <span>行程满意度:</span>
                    <el-rate v-model="item.travel_score" show-score text-color="#ff9900" disabled>
					</el-rate></p>
                    <p> 
                        <span>领队满意度:</span>
                        <el-rate v-model="item.leader_score" show-score text-color="#ff9900" disabled>
                        </el-rate>
                    </p>
                    <p> <span>我的点评:</span>{{item.content}}</p>
                    <div v-if="item.img">
                        <template v-for="it in item.img.split(',')" >
                                <el-image  :key="it" :src="it" fit="fill" style="max-width:120px"
                                :preview-src-list="item.img.split(',')">
                            </el-image>
                        </template>
                    </div>
                </div>
                 <div class="c3" style="margin-top:20px" v-if="item.reply_conent">
                       <p > 
                        <span>官方回复:</span>
                        {{item.reply_conent || "暂无回复"}}
                    </p>
                 </div>
			</div>

		</div> 

        <div class="page">
			  <el-pagination
				layout="prev, pager, next"
				:total="total"
				@current-change="handleCurrentChange"
				:page-size="pageSize"
                :hide-on-single-page="total<=pageSize">
				</el-pagination>
		  </div>
	</div>

</template>

<script>
import { userEvaluationListAPI} from "@/api/user";
export default {

  data () {
    return {
      sign: 1,
      list:[],
        total:1,
        page:1,
        pageSize:3
    }
  },
  created () {
      this.getList()
  },
  filters:{
      status(s){
          if (s==0) {
              return "待审核"
          } else  if (s==1) {
              return "审核通过"
          }else  if (s==2) {
              return "审核不通过"
          }
      }
  },
  methods: {
  
    handleCurrentChange(currentPage){
        this.page=currentPage
        this.getList()
    },
    getList(){
		
        var data={
            page:this.page,
            pageSize:this.pageSize,
					
		}
		  userEvaluationListAPI(data).then(res=>{
			  this.list=res.cateList
                this.total=res.rowsCount
		  })
	  }
  }
}
</script>

<style lang="scss" scoped="scoped">

	.user{
		padding: 5px 20px 20px;
		background: #ffffff;
        min-height: 500px;
		h4{
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			font-weight: 500;
			line-height: 45px;
			
		}

		.coulist{
           .couitem{
               border-bottom: 2px solid rgb(230, 230, 230);
               padding:10px 0;
           }
           
           .c2{
               line-height: 30px;
               div{
                 
                   p{
                      text-indent: 5px;
                       font-size: 14px;
                       line-height: 40px;
                       color: #999999;
                       strong{
                           font-size: 16px;
                       }
                   }
               }
               >p{
                   font-size: 14px;
                  line-height: 30px;
               }
           }
            .c3{
                line-height: 44px;
                opacity: 1;
                background: #f9f9f9;
                border-radius: 4px;
                padding:3px 10px;
                span{
                    color: #FF7C00;
                    margin-right: 5px;
                    border-radius: 3px;
                }
                p{
                    display: flex;
                    align-items: center;
                }
            }
			

		}
	}
.page{
		display: flex;
		margin-top: 20px;
		justify-content: flex-end;
	}
</style>
